<template>
  <b-sidebar
    :id="`${getId}-sidebar`"
    body-class="custom-scroll"
    v-model="showSideBar"
    :aria-labelledby="headerTitle"
    :aria-label="headerTitle"
    shadow
    bg-variant="white"
    :backdrop-variant="`dark`"
    no-close-on-esc
    no-close-on-backdrop
    lazy
    backdrop
    right
    :width="`${width}vw`"
  >
    <template #header="{ hide }">
      <h3 class="font-21 theme-font-medium mb-0" id="sidebar-title">{{ headerTitle }}</h3>
      <div class="sidebar-header-close">
        <a
          href="javascript:void(0)"
          role="button"
          id="sidebar-close-icon"
          :aria-labelledby="`${headerTitle} close`"
          @click="
            hide
            $emit('closeSideBar')
          "
          ><img src="@/assets/img/close.png" alt="close"
        /></a>
      </div>
    </template>
    <template #default="{}">
      <b-row>
        <b-col>
          <component ref="form" :is="componentName" :mode="'sidebar'" @close="$emit('closeSideBar')"></component>
        </b-col>
      </b-row>
    </template>
    <template #footer="{ hide }">
      <b-button
        v-if="isCloseBtn"
        type="button"
        id="sidebar-close-btn"
        v-activeBlur
        class="btn-h-44"
        variant="outline-secondary"
        @click="
          hide
          $emit('closeSideBar')
        "
        >Close
      </b-button>
      <b-button v-if="isResetBtn" id="sidebar-reset-btn" type="button" v-activeBlur class="btn-h-44" variant="outline-secondary" @click="onReset"
        >Reset</b-button
      >
      <b-button
        v-if="isSaveBtn"
        id="sidebar-save-btn"
        type="button"
        v-activeBlur
        :disabled="saveLoadingIcon"
        @click="onSave"
        variant="primary"
        class="ml-3 btn-h-44"
        >Save
        <b-spinner v-if="saveLoadingIcon" label="Spinning" small class="ml-2"></b-spinner>
      </b-button>
    </template>
  </b-sidebar>
</template>
<script>
import { ref, onMounted, watch } from 'vue'
export default {
  name: 'SideBar',
  setup() {
    const form = ref(null)
    const saveLoadingIcon = ref(false)

    onMounted(() => {
      watch(
        () => form.value?.addEditSecurityMethodLoading,
        (newVal) => {
          saveLoadingIcon.value = newVal
        }
      )
    })

    return { form, saveLoadingIcon }
  },
  props: {
    width: {
      type: String,
      default: '50',
    },
    showSideBar: {
      type: Boolean,
      default: false,
    },
    headerTitle: {
      type: String,
      required: true,
    },
    componentName: {
      type: String,
      required: true,
    },
    isCloseBtn: {
      type: Boolean,
      default: true,
    },
    isSaveBtn: {
      type: Boolean,
      default: false,
    },
    isResetBtn: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getId() {
      return this.headerTitle ? this.headerTitle.toLowerCase().split(' ').join('-') : ''
    },
  },
  components: {
    AddEditSecurityMethod: () => import('@/components/settings/security/AddEditSecurityMethod.vue'),
    AddEditInternalLinkAccount: () => import('@/components/settings/link-accounts/add-link-account/AddEditInternalLinkAccount.vue'),
  },
  methods: {
    onSave() {
      const form = this.$refs.form
      form.onSubmit()
    },
    onReset() {
      const form = this.$refs.form
      form.onReset()
    },
  },
}
</script>
